<template>
  <div class="pageContent">
    <div class="newsDetails">
      <div class="goback" @click="goBack()"><i class="el-icon-arrow-left"></i>返回</div>
      <div style="padding: 20px">
        <p class="newsTitle">{{ newsDetails.title }}</p>
        <div class="newsContent" v-html="newsDetails.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newsDetails: {
        title: null,
        titleImg: null,
        subtitle: null,
        source: null,
        content: null
      }
    };
  },
  components: {
    // searchHeader
  },
  mounted() {
    this.getNewsDetails();
  },
  created() {
      document.getElementsByClassName('el-main')[0].scrollTop = 0
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getNewsDetails() {
      protocolFwd.param_getContentDetails.param.id = this.$route.params.id;
      http.getRes(protocolFwd.param_getContentDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.newsDetails = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.newsDetails {
    box-sizing: border-box;
//   position: relative;
//   padding: 15px;
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  // min-height: 1000px;
  height: 100%;
  margin: 0 auto;
  margin-top: 15px;
  background-color: #ffffff;
  border: 1px solid #E6ECF2;
  box-sizing: border-box;
}
.newsDetails .goback {
//   position: absolute;
//   color: $commonThemeColor;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    padding: 15px;
    font-size: 16px;
    background-color: #F2F6FA;
}
.newsDetails .newsTitle {
  font-size: 18px;
  line-height: 45px;
  font-weight: 700;
  text-align: center;
}
.newsDetails .newsImage {
  width: 600px;
  margin: 10px auto;
}
.newsDetails .newsImage img {
  width: 100%;
}
.newsDetails .newsSource,
.newsDetails .newsDesc,
.newsDetails .newsContent {
  line-height: 25px;
  color: #9999a2;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
}
</style>



